import Vue from 'vue'
import Vuex from 'vuex'

import user from './user'
import app from './app'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    setting: {},
    showLogin: false,
    showRegister: false,
	showForgot:false,
  },
  getters: {
    setting: state => state.setting,
    showLogin: state => state.showLogin,
    showRegister: state => state.showRegister,
	showForgot: state => state.showForgot
  },
  mutations: {
    setSetting(state, setting = {}) {
      state.setting = setting
    },
    showLoginDialog(state, val = true) {
      state.showLogin = val
    },
    showRegisterDialog(state, val = true) {
      state.showRegister = val
    },
    showForgotDialog(state, val = true) {
      state.showForgot = val
    }
  },
  actions: {
  },
  modules: { user, app }
})
