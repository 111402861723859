import axios from './api.request'

/**
 * 获取首页页面数据
 */
export const getHomeData = (params) => {
  return axios.request({
    url: 'Index/index',
    method: 'get',
    params
  })
}
