<template>
  <Modal class="dialog" v-model="pShown" title="" :mask-closable="false" scrollable :width="420">
    <div class="dialog-header" slot="header">
      <div class="title">{{ systemSetting && systemSetting.title ? systemSetting.title : '类众' }}</div>
    </div>
    <Form ref="mform" :model="mform" :rules="mrules">
      <FormItem prop="mobile">
        <Input v-model="mform.mobile" placeholder="手机号" key="mobile"></Input>
      </FormItem>
	  <FormItem prop="password">
	    <Input v-model="mform.password" placeholder="新密码" key="password"></Input>
	  </FormItem>
      <FormItem prop="code">
        <Input v-model="mform.code" placeholder="验证码" key="code" @keyup.enter.native="onLogin">
          <span slot="append">
            <a class="code-btn" @click="onGetCode">{{ countDown === 0 ? '获取验证码' : countDown }}</a>
          </span>
        </Input>
      </FormItem>
    </Form>
    <div class="row">
      <span>
        <span>还没有账号？</span>
        <a class="a-btn" @click="onRegister">去注册</a>
      </span>
    </div>
    <div slot="footer">
      <Button type="primary" long :disabled="requesting" @click="onLogin" style="margin: 10px 0;">重置密码</Button>
    </div>
  </Modal>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import { sendMessage } from '@/api/user.js'
import { getSystemSetting } from '@/api/setting'

export default {
  name: 'Forgot',
  model: {
    prop: 'shown',
    event: 'change'
  },
  props: {
    shown: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      pShown: this.shown,
	  // pShown: true,
      loginMode: '1',
      requesting: false,
      aform: {
        userName: null,
        password: null
      },
      mform: {
        mobile: null,
		password:null,
        code: null
      },
      countDownTimer: null,
      countDown: 0,

      systemSetting: null
    }
  },
  computed: {
    mrules() {
      return {
        mobile: [{ required: true, message: '手机号不能为空', trigger: 'blur' }],
        password: [{ required: true, message: '密码不能为空', trigger: 'blur' }],
        code: [{ required: true, message: '动态码不能为空', trigger: 'blur' }]
      }
    }
  },
  watch: {
    pShown: function (newVal) {
      this.$emit('change', newVal)
      if (!newVal) {
        setTimeout(() => {
          if (this.$refs.aform) {
            this.$refs.aform.resetFields()
          }
          if (this.$refs.mform) {
            this.$refs.mform.resetFields()
          }
        }, 300)
        this.showForgotDialog(false)
      }
    },
    shown: function (newVal) {
      this.pShown = newVal
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    ...mapMutations(['showLoginDialog', 'showRegisterDialog','showForgotDialog']),
    ...mapActions(['handleLogin','forgotPassword']),
    async initData() {
      await this.getSystemSetting()
    },
    async getSystemSetting() {
      const res = await getSystemSetting()
      this.systemSetting = res.content
    },
    async onGetCode() {
      if (this.mform.mobile !== null && this.mform.mobile !== '') {
        if (this.countDown === 0) {
          this.countDown = 60
          this.countDownTimer = setInterval(() => {
            this.countDown--
            if (this.countDown === 0) {
              clearInterval(this.countDownTimer)
              this.countDown = 0
            }
          }, 1000)
		await sendMessage({ phone_number: this.mform.mobile }).then(response => {
			  }).catch(err => {
				this.$Message.error(err.message)
				clearInterval(this.countDownTimer)
				this.countDown = 0
          })
        } 
      }
    },
    onRegister() {
      this.pShown = false
      this.showRegisterDialog()
    },
	//修改密码
    onLogin() {
        this.$refs.mform.validate(async (valid) => {
          if (!valid) {
            return
          }
          const params = {
            phone_number: this.mform.mobile,
            password: this.mform.password,
            code: this.mform.code
          }
          this.requesting = true

          try {
          var res=    await this.forgotPassword(params)
		  console.log(res)
            this.$Message.success('修改成功！')
            setTimeout(() => {
              this.pShown = false
              this.$router.go(0)
            }, 1000)
          } catch (err) {
            this.$Message.error(err.message)
          }

          this.requesting = false
        })
    }
  }
}
</script>

<style lang="less" scoped>
.dialog {
  ::v-deep .ivu-modal-header {
    border-bottom: none;
    padding: 14px 0;
  }
  ::v-deep .ivu-modal-body {
    padding: 16px 40px;
  }
  ::v-deep .ivu-modal-footer {
    padding: 12px 40px;
  }
  ::v-deep .ivu-input {
    height: 40px;
    padding: 10px;
  }
}

.dialog-header {
  .title {
    padding: 10px;
    text-align: center;
    font-size: 28px;
    font-weight: 500;
    color: #17233d;
    padding: 10px 0;
  }
  ::v-deep .ivu-menu {
    display: flex;
    justify-content: center;
  }
}

.code-btn {
  display: block;
  font-size: 14px;
  color: #4183c4;
  width: 100px;
  &:hover{
    filter: opacity(0.6);
  }
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.a-btn {
  display: inline-block;
  font-size: 14px;
  color: #4183c4;
  width: fit-content;
  &:hover {
    filter: opacity(0.6);
  }
}
</style>
