export const forEach = (arr, fn) => {
	if (!arr.length || !fn) return
	let i = -1
	const len = arr.length
	while (++i < len) {
		const item = arr[i]
		fn(item, i, arr)
	}
}

/**
 * @param {Array} arr1
 * @param {Array} arr2
 * @description 得到两个数组的交集, 两个数组的元素为数值或字符串
 */
export const getIntersection = (arr1, arr2) => {
	const len = Math.min(arr1.length, arr2.length)
	let i = -1
	const res = []
	while (++i < len) {
		const item = arr2[i]
		if (arr1.indexOf(item) > -1) res.push(item)
	}
	return res
}

/**
 * @param {Array} arr1
 * @param {Array} arr2
 * @description 得到两个数组的并集, 两个数组的元素为数值或字符串
 */
export const getUnion = (arr1, arr2) => {
	return Array.from(new Set([...arr1, ...arr2]))
}

/**
 * @param {Array} target 目标数组
 * @param {Array} arr 需要查询的数组
 * @description 判断要查询的数组是否至少有一个元素包含在目标数组中
 */
export const hasOneOf = (targetarr, arr) => {
	return targetarr.some(_ => arr.indexOf(_) > -1)
}

/**
 * @param {String|Number} value 要验证的字符串或数值
 * @param {*} validList 用来验证的列表
 */
export function oneOf(value, validList) {
	for (let i = 0; i < validList.length; i++) {
		if (value === validList[i]) {
			return true
		}
	}
	return false
}

/**
 * @param {Number} timeStamp 判断时间戳格式是否是毫秒
 * @returns {Boolean}
 */
const isMillisecond = timeStamp => {
	const timeStr = String(timeStamp)
	return timeStr.length > 10
}

/**
 * @param {Number} timeStamp 传入的时间戳
 * @param {Number} currentTime 当前时间时间戳
 * @returns {Boolean} 传入的时间戳是否早于当前时间戳
 */
const isEarly = (timeStamp, currentTime) => {
	return timeStamp < currentTime
}

/**
 * @param {Number} num 数值
 * @returns {String} 处理后的字符串
 * @description 如果传入的数值小于10，即位数只有1位，则在前面补充0
 */
const getHandledValue = num => {
	return num < 10 ? '0' + num : num
}

/**
 * @param {Number} timeStamp 传入的时间戳
 * @param {Number} startType 要返回的时间字符串的格式类型，传入'year'则返回年开头的完整时间
 */
const getDate = (timeStamp, startType) => {
	const d = new Date(timeStamp * 1000)
	const year = d.getFullYear()
	const month = getHandledValue(d.getMonth() + 1)
	const date = getHandledValue(d.getDate())
	const hours = getHandledValue(d.getHours())
	const minutes = getHandledValue(d.getMinutes())
	const second = getHandledValue(d.getSeconds())
	let resStr = ''
	if (startType === 'year') resStr = year + '-' + month + '-' + date + ' ' + hours + ':' + minutes + ':' + second
	else resStr = month + '-' + date + ' ' + hours + ':' + minutes
	return resStr
}

/**
 * @param {String|Number} timeStamp 时间戳
 * @returns {String} 相对时间字符串
 */
export const getRelativeTime = timeStamp => {
	// 判断当前传入的时间戳是秒格式还是毫秒
	const IS_MILLISECOND = isMillisecond(timeStamp)
	// 如果是毫秒格式则转为秒格式
	if (IS_MILLISECOND) Math.floor(timeStamp /= 1000)
	// 传入的时间戳可以是数值或字符串类型，这里统一转为数值类型
	timeStamp = Number(timeStamp)
	// 获取当前时间时间戳
	const currentTime = Math.floor(Date.parse(new Date()) / 1000)
	// 判断传入时间戳是否早于当前时间戳
	const IS_EARLY = isEarly(timeStamp, currentTime)
	// 获取两个时间戳差值
	let diff = currentTime - timeStamp
	// 如果IS_EARLY为false则差值取反
	if (!IS_EARLY) diff = -diff
	let resStr = ''
	const dirStr = IS_EARLY ? '前' : '后'
	// 少于等于59秒
	if (diff <= 59) resStr = diff + '秒' + dirStr
	// 多于59秒，少于等于59分钟59秒
	else if (diff > 59 && diff <= 3599) resStr = Math.floor(diff / 60) + '分钟' + dirStr
	// 多于59分钟59秒，少于等于23小时59分钟59秒
	else if (diff > 3599 && diff <= 86399) resStr = Math.floor(diff / 3600) + '小时' + dirStr
	// 多于23小时59分钟59秒，少于等于29天59分钟59秒
	else if (diff > 86399 && diff <= 2623859) resStr = Math.floor(diff / 86400) + '天' + dirStr
	// 多于29天59分钟59秒，少于364天23小时59分钟59秒，且传入的时间戳早于当前
	else if (diff > 2623859 && diff <= 31567859 && IS_EARLY) resStr = getDate(timeStamp)
	else resStr = getDate(timeStamp, 'year')
	return resStr
}

/**
 * @param {Number} invervals 时间间隔，秒
 * @returns {String} 字符串描述
 */
export const descriptionForIntervals = invervals => {
	// 毫秒
	invervals = Math.floor(invervals /= 1000)

	let days = 0
	let hours = 0
	let minutes = 0
	let seconds = 0
	if (invervals <= 59) {
		// 少于等于59秒
		seconds = invervals
	} else if (invervals > 59 && invervals <= 3599) {
		// 多于59秒，少于等于59分钟59秒
		minutes = Math.floor(invervals / 60)
		seconds = invervals % 60
	} else if (invervals > 3599 && invervals <= 86399) {
		// 多于59分钟59秒，少于等于23小时59分钟59秒
		hours = Math.floor(invervals / 3600)
		const rest = invervals % 3600
		minutes = Math.floor(rest / 60)
		seconds = rest % 60
	} else {
		// 多于23小时59分钟59秒，少于等于29天59分钟59秒
		days = Math.floor(invervals / 86400)
		let rest = invervals % 86400
		hours = Math.floor(rest / 3600)
		rest = rest % 3600
		minutes = Math.floor(rest / 60)
		seconds = rest % 60
	}

	return `${days}天${hours}小时${minutes}分钟${seconds}秒`
}

/**
 * 判断时间对象是否有效
 */
export const isVaildDate = (date) => {
	return date instanceof Date && !isNaN(date.getTime())
}

/**
 * 字符串转化成时间对象
 */
export const dateFromString = (timeStr) => {
	const date = new Date(timeStr.replace(/-/g, '/'))
	if (isVaildDate(date)) {
		return date
	}
	return null
}

/**
 * 格式化时间
 * yyyy-MM-dd hh:mm:ss
 */
export const formatDateTime = (dateTime, format) => {
	if (dateTime == null) return null

	var o = {
		'M+': dateTime.getMonth() + 1, // month
		'd+': dateTime.getDate(), // day
		'h+': dateTime.getHours(), // hour
		'm+': dateTime.getMinutes(), // minute
		's+': dateTime.getSeconds(), // second
		'q+': Math.floor((dateTime.getMonth() + 3) / 3), // quarter
		S: dateTime.getMilliseconds() // millisecond
	}
	if (/(y+)/.test(format)) {
		format = format.replace(RegExp.$1,
			(dateTime.getFullYear() + '').substr(4 - RegExp.$1.length))
	}
	for (var k in o) {
		if (new RegExp('(' + k + ')').test(format)) {
			format = format.replace(RegExp.$1,
				RegExp.$1.length == 1 ? o[k] :
				('00' + o[k]).substr(('' + o[k]).length))
		}
	}
	return format
}

/**
 * @returns {String} 当前浏览器名称
 */
export const getExplorer = () => {
	const ua = window.navigator.userAgent
	const isExplorer = (exp) => {
		return ua.indexOf(exp) > -1
	}
	if (isExplorer('MSIE')) return 'IE'
	else if (isExplorer('Firefox')) return 'Firefox'
	else if (isExplorer('Chrome')) return 'Chrome'
	else if (isExplorer('Opera')) return 'Opera'
	else if (isExplorer('Safari')) return 'Safari'
}

/**
 * @description 绑定事件 on(element, event, handler)
 */
export const on = (function() {
	if (document.addEventListener) {
		return function(element, event, handler) {
			if (element && event && handler) {
				element.addEventListener(event, handler, false)
			}
		}
	} else {
		return function(element, event, handler) {
			if (element && event && handler) {
				element.attachEvent('on' + event, handler)
			}
		}
	}
})()

/**
 * @description 解绑事件 off(element, event, handler)
 */
export const off = (function() {
	if (document.removeEventListener) {
		return function(element, event, handler) {
			if (element && event) {
				element.removeEventListener(event, handler, false)
			}
		}
	} else {
		return function(element, event, handler) {
			if (element && event) {
				element.detachEvent('on' + event, handler)
			}
		}
	}
})()

/**
 * 判断一个对象是否存在key，如果传入第二个参数key，则是判断这个obj对象是否存在key这个属性
 * 如果没有传入key这个参数，则判断obj对象是否有键值对
 */
export const hasKey = (obj, key) => {
	if (key) return key in obj
	else {
		const keysArr = Object.keys(obj)
		return keysArr.length
	}
}

/**
 * @param {*} obj1 对象
 * @param {*} obj2 对象
 * @description 判断两个对象是否相等，这两个对象的值只能是数字或字符串
 */
export const objEqual = (obj1, obj2) => {
	const keysArr1 = Object.keys(obj1)
	const keysArr2 = Object.keys(obj2)
	if (keysArr1.length !== keysArr2.length) return false
	else if (keysArr1.length === 0 && keysArr2.length === 0) return true
	/* eslint-disable-next-line */
	else return !keysArr1.some(key => obj1[key] != obj2[key])
}

/**
 * @param str 字符串
 * @param end 字符串
 * @description 判断字符串str，是否以end结尾
 */
export const strEndWith = (str, end) => {
	const len = str.length - end.length
	return (len > 0 && str.lastIndexOf(end) === len)
}


export const imageUrlToFile = (imageUrl) => {
	return new Promise(function(resolve, reject) {
		let image = new Image() // 一定要设置为let，不然图片不显示
		image.setAttribute('crossOrigin', 'anonymous') // 解决跨域问题
		image.src = imageUrl
		image.onload = () => {
			var canvas = document.createElement("canvas")
			canvas.width = image.width
			canvas.height = image.height
			var context = canvas.getContext('2d')
			context.drawImage(image, 0, 0, image.width, image.height)
			var quality = 0.8
			var dataURL = canvas.toDataURL("image/jpeg",quality) // 使用toDataUrl将图片转换成jpeg的格式,不要把图片压缩成png，因为压缩成png后base64的字符串可能比不转换前的长！
			// var dataURL = canvas.toDataURL("image/png", quality)
			resolve(base64toFile(dataURL))
		}
	});
}

export const base64toFile = (dataurl, filename = 'file') => {
	let arr = dataurl.split(',')
	let mime = arr[0].match(/:(.*?);/)[1]
	let suffix = mime.split('/')[1]
	let bstr = atob(arr[1])
	let n = bstr.length
	let u8arr = new Uint8Array(n)
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n)
	}
	let file = new File([u8arr], `${filename}.${suffix}`, {
		type: mime
	})
	return file
}
