import axios from './api.request'

/**
 * 注册用户
 */
export const userRegister = (params) => {
  return axios.request({
    url: 'User/register',
    data: params,
    method: 'post'
  })
} 

/**
 * 登录
 */
export const login = (params) => {
  return axios.request({
    url: 'User/login',
    data: params,
    method: 'post'
  })
}

/**
 * 修改密码
 */
export const forgotPassword = (params) => {
  return axios.request({
    url: 'User/forgotPassword',
    data: params,
    method: 'post'
  })
}

/**
 * 发送短信
 */
export const sendMessage = (params) => {
  return axios.request({
    url: 'User/sendMessage',
    data: params,
    method: 'post'
  })
}

/**
 * 登出
 */
export const logout = () => {
  return axios.request({
    url: 'User/logout',
    method: 'post'
  })
}

/**
 * 获取个人信息
 */
export const getUserInfo = () => {
  return axios.request({
    url: 'User/userInfo',
    method: 'get'
  })
}

/**
 * 修改个人信息
 */
export const modifyUserInfo = (fileDes, params) => {
  return axios.uploadFile('User/modifyUserInfo', fileDes, params)
}

/**
 * 修改个人收款二维码
 */
export const verificationBusinessLicense = (fileDes) => {
  return axios.uploadFile('User/verificationBusinessLicense', fileDes, null)
}

/**
 * 修改个人收款二维码
 */
export const modifyUserQrCode = (fileDes) => {
  return axios.uploadFile('User/modifyUserQrCode', fileDes, null)
}

/**
 * 修改登录密码
 */
export const changePassword = (old_passwd, new_passwd) => {
  return axios.request({
    url: 'User/changePassword',
    data: {old_passwd, new_passwd},
    method: 'post'
  })
}

/**
 * 获取vip的支付二维码
 */
export const getVipPayQr = (params) => {
  return axios.request({
    url: 'Wepay/vipOrder',
    params,
    method: 'get'
  })
}

/**
 * 获取发布的支付状态
 */
export const checkTransaction = (params) => {
  return axios.request({
    url: 'Wepay/checkTransaction',
    params,
    method: 'get'
  })
}

/**
 * 获取发布的支付二维码
 */
export const getReleasePayQr = (params) => {
  return axios.request({
    url: 'Wepay/releaseOrder',
    params,
    method: 'get'
  })
}

/**
 * 检查发布是否已支付
 */
export const checkReleaseIsPaid = (params) => {
  return axios.request({
    url: 'Wepay/checkReleaseIsPaid',
    params,
    method: 'get'
  })
}

/**
 * 获取我购买的记录
 */
export const getOrderList = (params) => {
  return axios.request({
    url: 'User/getOrderList',
    params,
    method: 'get'
  })
}

/**
 * 获取我推荐的用户
 */
export const getMyInviteUser = (params) => {
  return axios.request({
    url: 'User/getMyInviteUser',
    params,
    method: 'get'
  })
}

/**
 * 获取盖章方和签名方选项列表，排除已添加的
 */
export const getSealAndSignerOptions = () => {
  return axios.request({
    url: 'User/getSealAndSignerOptions',
    method: 'get'
  })
}

/**
 * 保存盖章方和签名方
 */
export const saveSealAndSigners = (fileDes, data) => {
  if (fileDes.length > 0) {
    return axios.uploadFile('User/saveSealAndSigners', fileDes, data)
  } else {
    return axios.request({
      url: 'User/saveSealAndSigners',
      data,
      method: 'post'
    })
  }
}

/**
 * 删除盖章方
 */
export const deleteSeal = (data) => {
  return axios.request({
    url: 'User/deleteSeal',
    data,
    method: 'post'
  })
}

/**
 * 删除签名方
 */
export const deleteSigner = (data) => {
  return axios.request({
    url: 'User/deleteSigner',
    data,
    method: 'post'
  })
}


/**
 * 获取盖章方和签名方选项列表，排除已添加的
 */
export const getContractHistory = () => {
  return axios.request({
    url: 'User/getContractHistory',
    method: 'get'
  })
}