import {
  getBreadCrumbList,
  setTagNavListInLocalstorage,
  getMenuByRouter,
  getTagNavListFromLocalstorage,
  getHomeRoute,
  getNextRoute,
  routeHasExist,
  routeEqual,
  getRouteTitleHandled,
  localSave,
  localRead
} from '@/libs/util'
import router from '@/router'
import routers from '@/router/routers'
import config from '@/config'

const closePage = (state, route, always) => {
  let nextRoute = null

  if (route.meta && route.meta.afterClose) {
    const index = state.tagNavList.findIndex(item => item.name === route.meta.afterClose)
    if (index !== -1) {
      nextRoute = state.tagNavList[index]
    } else if (always) {
      state.tagNavList = state.tagNavList.filter(item => {
        return !routeEqual(item, route)
      })
      router.push({name: route.meta.afterClose})
      return
    }
  }

  if (nextRoute == null) {
    nextRoute = getNextRoute(state.tagNavList, route)
  }

  state.tagNavList = state.tagNavList.filter(item => {
    return !routeEqual(item, route)
  })

  router.push(nextRoute)
}

export default {
  state: {
    breadCrumbList: [],
    tagNavList: [],
    homeRoute: {},
    detailInfo: localRead('detailInfo') || {} // {type : info}
  },
  mutations: {
    setBreadCrumb(state, route) {
      state.breadCrumbList = getBreadCrumbList(route, state.homeRoute)
    },
    setHomeRoute(state, routes) {
      state.homeRoute = getHomeRoute(routes, config.homeName)
    },
    setTagNavList(state, list) {
      let tagList = []
      if (list) {
        tagList = [...list]
      } else {
        tagList = getTagNavListFromLocalstorage() || []
      }
      if (tagList[0] && tagList[0].name !== config.homeName) tagList.shift()
      const homeTagIndex = tagList.findIndex(item => item.name === config.homeName)
      if (homeTagIndex > 0) {
        const homeTag = tagList.splice(homeTagIndex, 1)[0]
        tagList.unshift(homeTag)
      }
      state.tagNavList = tagList
      setTagNavListInLocalstorage([...tagList])
    },
    updateTag(state, {route, title}) {
      const len = state.tagNavList.length
      for (let i = 0; i < len; i++) {
        const item = state.tagNavList[i]
        if (routeEqual(item, route)) {
          item.meta.title = title
          setTagNavListInLocalstorage([...state.tagNavList])
          break
        }
      }
      route.meta.title = title
      if (state.homeRoute.name) {
        state.breadCrumbList = getBreadCrumbList(route, state.homeRoute)
      }
    },
    closeTag(state, {route, always = true}) {
      const tag = state.tagNavList.filter(item => routeEqual(item, route))
      route = tag[0] ? tag[0] : null
      if (!route) return
      closePage(state, route, always)
    },
    addTag(state, { route, type = 'unshift' }) {
      const router = getRouteTitleHandled(route)
      const index = routeHasExist(state.tagNavList, router)
      if (index === -1) {
        if (type === 'push') state.tagNavList.push(router)
        else {
          if (router.name === config.homeName) state.tagNavList.unshift(router)
          else state.tagNavList.splice(1, 0, router)
        }
        setTagNavListInLocalstorage([...state.tagNavList])
      } else if (!config.compareRouteStrict) {
        state.tagNavList[index] = route
        setTagNavListInLocalstorage([...state.tagNavList])
      }
    }
  },
  getters: {
    menuList: (state, getters, rootState) => getMenuByRouter(routers, rootState.user.access)
  },
  actions: {
  }
}
