import Vue from 'vue'
import VueRouter from 'vue-router'
import ViewUI from 'view-design'

import routes from './routers'
import store from '@/store'
import config from '@/config'
import { setTitle, canTurnTo, routeEqual } from '@/libs/util'

// 避免报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(() => {
    ViewUI.LoadingBar.finish()
  })
}

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(() => {
    ViewUI.LoadingBar.finish()
  })
}

const stop = (from, next) => {
  setTitle(from, router.app)
  ViewUI.LoadingBar.finish()
  next(false)
}

const turnTo = (to, from, access, next) => {
  if (!canTurnTo(to.name, access, routes)) {
    if (from.name == 'error_401') {
      return stop(from, next)
    }
    // 无权限，重定向到401页面
    if (to.name != 'error_401') {
      return next({ replace: true, name: 'error_401' })
    }
  }
  // 有权限，可访问
  next()
}

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  console.log(`beforeEach to: ${to.name} from: ${from.name}`)
  if (routeEqual(to, from)) return stop(from, next)

  ViewUI.LoadingBar.start()

  const token = store.getters.userToken
  const homeName = config.homeName

  //
  // 登录控制
  //
  if (token != null) {
    if (!store.getters.hasLogin) {
      store.dispatch('getUserInfo').then(res => {
        turnTo(to, from, null, next)
      }).catch(err => {
        console.log('getUserInfo error: ', err)
        store.commit('setToken', null)
        next({name: homeName})
      })
    } else {
      turnTo(to, from, null, next)
    }
  } else {
    if (to.name == 'user') {
      next({name: homeName})
    } else {
      next()
    }
  }
})

router.afterEach(to => {
  setTitle(to, router.app)
  ViewUI.LoadingBar.finish()
  window.scrollTo(0, 0)
})

export default router
