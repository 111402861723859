import { setToken, getToken } from '@/libs/util'
import { login, forgotPassword,logout, getUserInfo } from '@/api/user'
import router from '@/router'
import config from '@/config'
import { getHomeData } from '@/api/home'

export default {
  state: {
    token: getToken(),
    userInfo: {},
    cityId: null,
    city: '',
    timestampUserInfo: null,
    homeData: null
  },
  mutations: {
    setToken(state, token) {
      state.token = token
      setToken(token)
    },
    setUserInfo(state, info = {}) {
      state.userInfo = info

      // 保存请求时间
      if (Object.keys(info).length === 0) {
        state.timestampUserInfo = null
      } else {
        state.timestampUserInfo = (new Date()).getTime()
      }
    },
    setCityId(state, cityId) {
      state.cityId = cityId
    },
    setCity(state, city) {
      state.city = city
    },
    setHomeData(state, homeData) {
      state.homeData = homeData
    }
  },
  getters: {
    userToken: state => state.token,
    hasLogin: state => state.userInfo.id != null,
    userInfo: state => state.userInfo,
    userAvatar: state => state.userInfo.avatar_url,
    cityId: state => state.cityId,
    city: state => state.city,
    homeData: state => state.homeData
  },
  actions: {
    // 登录
    handleLogin({ commit }, { userName, password, phone_number, code }) {
      return new Promise((resolve, reject) => {
        login(userName ? {account: userName, password} : { phone_number, code }).then(res => {
          // 保存用户信息
          commit('setToken', res.content.user_token)
          commit('setUserInfo', res.content.user_info)
          commit('setCityId', res.content.user_info.city_id)
          commit('setCity', res.content.user_info.city)
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
		// 修改密码
		forgotPassword({ commit }, { phone_number, password,code }) {
		  return new Promise((resolve, reject) => {
		    forgotPassword({ phone_number,password, code }).then(res => {
		      resolve(res)
		    }).catch(err => {
		      reject(err)
		    })
		  })
		},
    async setCityAction({ commit }, { cityId, city }) {
      // console.log(cityId, city)
      commit('setCityId', cityId)
      commit('setCity', city)
      try {
        const res = await getHomeData({city_id: cityId})
        console.log(res)
        commit('setHomeData', res.content)
      } catch (error) {
        commit('setHomeData', null)
        // this.$Message.error(error.message)
      }
    },
    // 退出登录
    handleLogout({ commit }, { route }) {
      return new Promise((resolve, reject) => {
        resolve()
        logout().then(() => {
          commit('setToken', null)
          if (route.name == 'user') {
            router.push({name: config.homeName})
          }
          commit('setUserInfo', {})
          resolve()
        }).catch(err => {
          reject(err)
        })
      })
    },
    // 获取用户相关信息
    getUserInfo({ state, commit }, {force = true} = {}) {
      const hasData = Object.keys(state.userInfo).length > 0
      if (hasData && !force && state.timestampUserInfo != null) {
        // 避免重复请求用户信息
        const intervals = 60000 // 1分钟请求一次
        const timestamp = state.timestampUserInfo
        if ((new Date()).getTime() - timestamp < intervals) {
          return Promise.resolve()
        }
      }

      return new Promise((resolve, reject) => {
        getUserInfo().then(async res => {
          commit('setUserInfo', res.content.user_info)
          commit('setCityId', res.content.user_info.city_id)
          commit('setCity', res.content.user_info.city)
          try {
            const res2 = await getHomeData({city_id: res.content.user_info.city_id})
            console.log(res2)
            commit('setHomeData', res2.content)
          } catch (error) {
            commit('setHomeData', null)
            // this.$Message.error(error.message)
          }
          resolve(res.content)
        }).catch(err => {
          reject(err)
        })
      })
    }
  }
}
