<template>
  <div id="app" style="">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="less">
.size{
  width: 100%;
  height: 100%;
}
html,body{
  .size;
  margin: 0;
  padding: 0;
  overflow: auto;
  // overflow: hidden;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .size;
}
</style>
