import axios from './api.request'

/**
 * 获取价格配置
 */
export const getVipPriceData = () => {
  return axios.request({
    url: 'Setting/getVipPrices',
    method: 'get'
  })
}

/**
 * 获取会员配置
 */
export const getMemberSetting = () => {
  return axios.request({
    url: 'Setting/getMemberSetting',
    method: 'get'
  })
}

/**
 * 获取系统设置
 */
export const getSystemSetting = () => {
  return axios.request({
    url: 'Setting/getSystemSetting',
    method: 'get'
  })
}
