import axios from './api.request'

/**
 * 获取 changeCity 页面数据
 */
export const getChangeCityData = (zx_flag = 'n') => {
  return axios.request({
    url: 'City/index',
    params: {zx_flag},
    method: 'get'
  })
}

/**
 * 获取城市数据
 */
export const getRegionData = () => {
  return axios.request({
    url: 'City/regionData',
    method: 'get'
  })
}
