import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import config from './config'

import VueClipboard from 'vue-clipboard2'

// https://www.iviewui.com/docs/introduce
import ViewUI from 'view-design'

import Preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'

// import style
import './index.less'

import VueTinymce from '@packy-tang/vue-tinymce'
import tinymce from 'tinymce'

import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

Vue.use(ViewUI)
Vue.use(VueClipboard)
Vue.use(Preview)

Vue.prototype.$tinymce = tinymce // 将全局tinymce对象指向给Vue作用域下
Vue.use(VueTinymce) // 安装vue的tinymce组件

Vue.use(Viewer)
Viewer.setDefaults({
  Options: {
    inline: true,
    button: false,
    navbar: true,
    title: true,
    toolbar: true,
    tooltip: true,
    movable: true,
    zoomable: true,
    rotatable: true,
    scalable: true,
    transition: true,
    fullscreen: true,
    keyboard: true,
    url: 'data-source'
  }
})

/**
 * @description 全局注册应用配置
 */
Vue.prototype.$config = config

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
