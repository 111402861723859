import axios from 'axios'
import ViewUI from 'view-design'
import router from '@/router'
import store from '@/store'

/***
 * @class
 * 表示网络请求过程中发生的错误
 */
export const NetworkError = (function () {
  function NetworkError(code = 0, msg = null) {
    // 避免漏了 new
    if (!(this instanceof NetworkError)) {
      return new NetworkError(code, msg)
    }

    let message = '操作成功'
    if (code === -1) {
      message = '当前网络不可用，请检查你的网络设置' // 请求错误，网络未连接，超时等
    } else if (code === -2) {
      message = '接口返回错误数据' // 接口返回错误数据
    } else if (code === -3) {
      message = '重新请求' // 重新请求
    } else if (code === -4) {
      message = '需要授权登录' // 需要授权登录
    } else if (code === -5) {
      message = '需要绑定手机号' // 需要绑定手机号
    } else if (code === -500) {
      message = '请求已取消' // 请求已取消
    } else if (code === 401) {
      message = '您还没有登录'
    } else if (code === 402) {
      message = '登录已过期，请重新登录'
    } else if (code !== 0) {
      message = msg || '未知错误' // 未知错误
    }

    Error.call(this, message)
    this.code = code
    this.name = 'NetworkError'
    this.message = message
  }

  NetworkError.prototype = Object.create(Error.prototype)
  NetworkError.prototype.constructor = NetworkError

  return NetworkError
})()

/***
 * 统一处理接口请求
 * 返回 Promise 对象
 * data = {
 *  status: "ok" | "error"
 *  code: int 状态码
 *  content: array|string 数据内容或提示语句
 * }
 */
function _handleResponse(result, logMsg = '', ignoreCodes = null) {
  return new Promise((resolve, reject) => {
    if (!result || !Object.prototype.hasOwnProperty.call(result, 'status') ||
      !Object.prototype.hasOwnProperty.call(result, 'code') ||
      !Object.prototype.hasOwnProperty.call(result, 'content')) {
      return reject(new NetworkError(-2))
    }

    if (logMsg.length > 0) {
      console.log(logMsg, result)
    }

    //
    // 服务器接口返回正确数据
    if (result.status == 'ok') {
      return resolve(result)
    } else {
      // 忽略的错误
      const len = ignoreCodes ? ignoreCodes.length : 0
      for (let i = 0; i < len; i++) {
        if (ignoreCodes[i] == result.code) {
          return resolve(result)
        }
      }

      // 特别处理’未登录或登录超时‘错误
      if (result.code == 401 || result.code == 402) {
        store.commit('setToken', null)
        store.commit('setUserInfo', {})
        return reject(new NetworkError(result.code))
      }

      //  服务器提示的错误
      if (typeof result.content === 'string') {
        return reject(new NetworkError(result.code, result.content))
      }
    }

    // 如果以上条件都不符合，可以断定服务器返回错误
    return reject(new NetworkError(-2))
  })
}

class HttpRequest {
  constructor(baseUrl) {
    this.baseUrl = baseUrl
    this.queue = {}
  }

  getInsideConfig() {
    const config = {
      baseURL: this.baseUrl,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }
    return config
  }

  destroy(url) {
    delete this.queue[url]
    if (!Object.keys(this.queue).length) {
      ViewUI.Spin.hide()
    }
  }

  interceptors(instance, options) {
    const url = options.url
    const logMsg = `${options.url} => `
    // 请求拦截
    instance.interceptors.request.use(config => {
      // 添加全局的loading...
      if (options.showSpin && !Object.keys(this.queue).length) {
        ViewUI.Spin.show()
      }
      this.queue[url] = true
      return config
    }, error => {
      return Promise.reject(error)
    })
    // 响应拦截
    instance.interceptors.response.use(res => {
      this.destroy(url)
      const { data, status } = res
      if (status == 200 || status == 304) {
        if (data instanceof Blob) {
          if (data.type == 'application/json') {
            return new Promise(resolve => {
              const reader = new FileReader()
              reader.onload = () => {
                const json = JSON.parse(reader.result)
                resolve(_handleResponse(json, logMsg, options.ignoreCodes))
              }
              reader.readAsText(data)
            })
          } else {
            return Promise.resolve(res)
          }
        }
        return _handleResponse(data, logMsg, options.ignoreCodes)
      } else {
        const err = new NetworkError(-2)
        console.error(logMsg, err)
        return Promise.reject(err)
      }
    }, error => {
      console.error(logMsg, error)
      this.destroy(url)
      return Promise.reject(error)
    })
  }

  request(options) {
    const instance = axios.create()
    // 加上公共的参数
    const param = {
      client: 'web', // 客户端类型
      token: store.getters.userToken
    }

    if (options.method == 'get') {
      options.params = Object.assign({}, param, options.params)
      options.params.dt_ = Math.random() // 防止读取缓存
    } else {
      options.data = Object.assign({}, param, options.data)
    }

    options = Object.assign(this.getInsideConfig(), options)
    this.interceptors(instance, options)
    return instance(options)
  }

  uploadFile(url, fileDes, params) {
    const instance = axios.create({
      baseURL: this.baseUrl,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    })

    const options = {
      url,
      method: 'post'
    }

    const formData = new FormData()
    formData.append('client', 'web')
    formData.append('token', store.getters.userToken)
    if (Object.prototype.toString.call(fileDes) === '[object Object]') {
      if (Object.prototype.toString.call(fileDes.file) === '[object Array]' || Object.prototype.toString.call(fileDes.file) === '[object FileList]') {
        fileDes.file.forEach(item => {
          formData.append(fileDes.name, item)
        })
      } else if (Object.prototype.toString.call(fileDes.file) === '[object File]') {
        formData.append(fileDes.name, fileDes.file)
      }
    } else if (Object.prototype.toString.call(fileDes) === '[object Array]') {
      fileDes.forEach(item => {
        if (Object.prototype.toString.call(item.file) === '[object Array]' || Object.prototype.toString.call(item.file) === '[object FileList]') {
          item.file.forEach(item2 => {
            formData.append(item.name, item2)
          })
        } else if (Object.prototype.toString.call(item.file) === '[object File]') {
          formData.append(item.name, item.file)
        }
        // formData.append(item.name, item.file)
      })
    }

    if (params) {
      Object.keys(params).forEach(key => {
        const value = params[key]
        if (value !== null) {
          formData.append(key, params[key])
        }
      })
    }
    options.data = formData

    this.interceptors(instance, options)
    return instance(options)
  }
}

export default HttpRequest
